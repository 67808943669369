import moment from "moment-timezone";
import { DATE_FORMAT } from "utils/date";

export const last7StartDate = moment()
  .subtract(7, "d")
  .format(DATE_FORMAT);

export const last30StartDate = moment()
  .subtract(30, "d")
  .format(DATE_FORMAT);

export const totalStartDate = moment()
  .subtract(5, "y")
  .format(DATE_FORMAT);

export const today = moment().format(DATE_FORMAT);

export const endDate = moment()
  .add(1, "y")
  .format(DATE_FORMAT);

export const ROW_STYLES: React.CSSProperties = {
  width: "100%",
};

export const INFO = "info";
export const EDIT = "edit";
export const TEST = "Test";
export const REMINDER = "Reminder";
export const CALENDAR = "CALENDAR";
export const CREATE = "create";
export const UPDATE = "update";
export const MEDICATION = "MEDICATION";
export const MEAL = "MEAL";
export const WEIGHT = "WEIGHT";
export const GLUCOSE = "GLUCOSE";
export const BLOOD_PRESSURE = "BLOOD_PRESSURE";
export const SLEEP = "SLEEP";
export const SLEEP_CONNECTED = "SLEEP_CONNECTED";
export const MISSED = "MISSED";
export const REJECTED = "REJECTED";
export const VERIFIED = "VERIFIED";
export const SUBMITTED = "SUBMITTED";
export const CREDITED = "CREDITED";
export const UNKNOWN = "UNKNOWN";
export const OTHER_ACTIONS = "OTHER_ACTIONS";
export const OPEN = "OPEN";
export const NOT_YET_OPEN = "NOT YET OPEN";
export const REWARDS = "REWARDS";
export const CANCEL = "Cancel";
export const SUBMIT = "Submit";
export const SAVE = "Save";
export const OK = "Ok";
export const SAVE_CHANGES = "Save Changes";
export const CREATE_REMINDER = "Create Reminder";
export const YES = "Yes";
export const EDIT_REMINDER = "Edit Reminder";
export const INFO_REMINDER = "Info Reminder";
export const CONFIRMATION = "Confirmation";
export const CLOSE = "Close";
export const SMS_TEMPLATES_EDIT = "SMS_TEMPLATES_EDIT";
export const SMS_TEMPLATES_USE = "SMS_TEMPLATES_USE";
export const SMS_TEMPLATES_TEST = "SMS_TEMPLATES_TEST";
export const SMS_TEMPLATES_CREATE = "SMS_TEMPLATES_CREATE";
export const CHANGE_STATUS = "Change Status";
export const DELETE_MEMBER = "Delete Member";
export const REOPEN_PROSPECT = "Re-Open Prospect";
export const MEMBER_EDUCATOR = "member_educator";
export const ADMIN = "admin";
export const SUPER_ADMIN = "super_admin";
export const APPOINTMENT = "Appointment";
export const ENROLLMENT = "ENROLLMENT";
