import React, { CSSProperties, useRef, useState } from "react";
import { FormInputType, Form, Button } from "@wellth/web-ui";
import { BUTTON_CONTAINER_STYLES } from "containers/MemberProfile/styles";
import { REASON_OPTIONS, REASON_TO_DETAILS_MATCH } from "./CreditReasonInputs";

const DROPDOWN_STYLE: React.CSSProperties = {
  height: "1rem",
  width: "27rem",
  paddingBottom: "1rem",
};

const NOTE_STYLE: React.CSSProperties = {
  minWidth: "27rem",
};

const CREDIT_TASK_STYLE = {
  padding: "0.5rem 0 0 0.5rem",
  fontWeight: "normal",
} as CSSProperties;

type CreditTaskFormValues = {
  reason: string;
  reasonDetail: string;
  note?: string;
};

interface CreditTaskCardProps {
  creditTask: (values: CreditTaskFormValues) => Promise<void>;
  loading: boolean;
  type: "check-in" | "appointment";
}

const CreditTaskCard: React.FC<CreditTaskCardProps> = ({
  creditTask,
  loading,
  type,
}) => {
  const formRef = useRef(null);
  const [reason, setReason] = useState(null);
  const [reasonDetail, setReasonDetail] = useState(null);
  const [reasonDetailOptions, setReasonDetailOptions] = useState([]);
  const [note, setNote] = useState(null);

  return (
    <div style={CREDIT_TASK_STYLE}>
      <Form
        wrappedComponentRef={formRef}
        inputs={[
          {
            type: FormInputType.Dropdown,
            title: "Reason",
            key: "reason",
            placeholder: "Select a reason",
            style: DROPDOWN_STYLE,
            options: REASON_OPTIONS[type],
            onChange: (value: string) => {
              setReason(value);
              setReasonDetail(null);
              setReasonDetailOptions(REASON_TO_DETAILS_MATCH[value]);
            },
            decoratorOptions: {
              rules: [{ required: true, message: "Please provide a reason" }],
            },
          },
          {
            type: FormInputType.Dropdown,
            title: "Reason Detail",
            key: "reasonDetail",
            disabled: !reason,
            style: DROPDOWN_STYLE,
            options: reasonDetailOptions,
            onChange: (value: string) => {
              setReasonDetail(value);
            },
            decoratorOptions: {
              rules: [
                { required: true, message: "Please provide a reason detail" },
              ],
            },
          },
          {
            type: FormInputType.TextArea,
            title: "Add note",
            key: "note",
            onBlur: (e) => {
              setNote(e.target.value);
            },
            autoSize: { minRows: 2 },
            style: NOTE_STYLE,
          },
        ]}
        mapPropsToFields={(_props, createFormField) => ({
          reason: createFormField({
            value: reason,
          }),
          reasonDetail: createFormField({ value: reasonDetail }),
          note: createFormField({ value: note }),
        })}
      />
      <div style={BUTTON_CONTAINER_STYLES}>
        <Button
          loading={loading}
          disabled={!reason || !reasonDetail}
          type="primary"
          onClick={async () => {
            const values = await new Promise((resolve, reject) =>
              formRef.current.props.form.validateFields((errors, values) =>
                errors ? reject(errors) : resolve(values),
              ),
            );
            creditTask(values as CreditTaskFormValues);
          }}
        >
          {`Credit ${type}`}
        </Button>
      </div>
    </div>
  );
};

export default CreditTaskCard;
