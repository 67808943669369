import React from "react";
import { AntInput, Popconfirm } from "@wellth/web-ui";

const { TextArea } = AntInput;

const INPUT_CONTAINER_STYLE = {
  padding: "0.625rem",
  display: "flex",
  justifyContent: "space-between",
};
const SEND_MESSAGE_STYLE = {
  padding: "0.3125rem",
};

export interface Props {
  onChange?: (value: string) => void;
  onConfirm?: () => void;
  popconfirm: boolean;
  buttonTitle: string;
  placeholder: string;
  popconfirmTitle?: string;
  value: string;
}

const MessageInput: React.FC<Props> = ({
  value,
  onChange,
  onConfirm,
  popconfirm,
  buttonTitle,
  placeholder,
  popconfirmTitle,
}: Props) => (
  <div style={INPUT_CONTAINER_STYLE}>
    <TextArea
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      autoSize
    />
    {popconfirm ? (
      <Popconfirm title={popconfirmTitle} onConfirm={onConfirm}>
        <h4 style={SEND_MESSAGE_STYLE}>{buttonTitle}</h4>
      </Popconfirm>
    ) : (
      <h4 onClick={onConfirm} style={SEND_MESSAGE_STYLE}>
        {buttonTitle}
      </h4>
    )}
  </div>
);

export default MessageInput;
