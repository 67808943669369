import React from "react";
import moment from "moment-timezone";
import { Calendar, SVG, Button } from "@wellth/web-ui";
import { TIME_FORMAT } from "utils/date";
import COLORS from "utils/colors";
import { CheckInCategory, AppointmentStatusType } from "types/globalTypes";
import { AppointmentType } from "containers/MemberProfile/components/TaskCalendar";
import { APPOINTMENT, ENROLLMENT } from "containers/MemberProfile/constants";

const CALENDAR_CONTAINER: React.CSSProperties = {
  height: "46.75rem",
};

const TIME_STYLES: React.CSSProperties = {
  marginLeft: "0.1875rem",
};

const NAV_CONTAINER: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
};

const NAV_TITLE: React.CSSProperties = {
  padding: 10,
  fontSize: "1.5rem",
};

const NAV_BUTTON: React.CSSProperties = {
  margin: ".15rem",
};

const IMAGE_HEIGHT = "0.6875rem";
const IMAGE_WIDTH = "0.875rem";

export enum CheckInStatus {
  submitted = "SUBMITTED",
  credited = "CREDITED",
  rejected = "REJECTED",
  missed = "MISSED",
  open = "OPEN",
  notYetOpen = "NOT YET OPEN",
}

export interface TaskEvent {
  start: Date;
  end: Date;
  taskCategory: CheckInCategory | AppointmentType["__typename"] | "ENROLLMENT";
  photoUrl: string;
  taskIsValid: boolean;
  taskStatus: CheckInStatus | AppointmentStatusType | "ENROLLMENT";
  isDemo: boolean;
  calendarDate: Date;
  taskId: string;
  taskTime: string;
  taskDate: string;
}

const eventStyleGetter = (event) => {
  const { taskStatus, taskIsValid } = event;
  let backgroundColor;
  let border = "0rem";
  let color = COLORS.white;

  switch (taskStatus) {
    case AppointmentStatusType.Completed:
    case CheckInStatus.submitted:
    case AppointmentStatusType.InProgress:
      backgroundColor = COLORS.green;
      break;
    case CheckInStatus.credited:
    case AppointmentStatusType.Credited:
      backgroundColor = COLORS.lightGreen;
      border = "0.0625rem";
      color = COLORS.green;
      break;
    case CheckInStatus.rejected:
    case CheckInStatus.missed:
    case AppointmentStatusType.Missed:
      backgroundColor = COLORS.red;
      break;
    case CheckInStatus.open:
      backgroundColor = COLORS.blue;
      break;
    case AppointmentStatusType.Other:
      backgroundColor = COLORS.yellow;
      break;
    case ENROLLMENT:
      backgroundColor = COLORS.white;
      color = COLORS.darkGray;
      break;
    default:
      backgroundColor = COLORS.gray;
      break;
  }
  if (
    !taskIsValid &&
    taskStatus !== CheckInStatus.credited &&
    taskStatus !== CheckInStatus.notYetOpen &&
    taskStatus !== CheckInStatus.open
  ) {
    backgroundColor = COLORS.red;
  }
  const style = {
    backgroundColor,
    borderRadius: "0rem",
    opacity: 0.8,
    color,
    border,
    borderStyle: "solid",
    borderColor: COLORS.green,
    display: "block",
    fontSize: ".9rem",
  };
  return {
    style,
  };
};

interface CalendarEventProps {
  event: TaskEvent;
}

const CalendarEvent = ({
  event: { isDemo, taskCategory, calendarDate, taskTime },
}: CalendarEventProps) => {
  let iconType = "";
  let eventText = (
    <span style={TIME_STYLES}>
      {moment(
        `${moment(calendarDate).format("YYYY-MM-DD")} ${taskTime}`,
      ).format(TIME_FORMAT)}
    </span>
  );
  switch (taskCategory.toUpperCase()) {
    case CheckInCategory.BloodPressure:
      iconType = "BLOODPRESSURE";
      break;
    case CheckInCategory.Meal:
      iconType = "MEAL";
      break;
    case CheckInCategory.Weight:
      iconType = "SCALE";
      break;
    case CheckInCategory.Glucose:
      iconType = "GLUCOSE";
      break;
    case CheckInCategory.Medication:
      iconType = "MEDICATION";
      break;
    case CheckInCategory.Sleep:
    case CheckInCategory.SleepConnected:
      iconType = "SLEEP";
      break;
    case APPOINTMENT.toUpperCase():
      iconType = "APPOINTMENT";
      break;
    case ENROLLMENT:
      iconType = "DEMO";
      eventText = <span>Enrollment Day</span>;
      break;
    default:
      break;
  }
  if (isDemo) {
    iconType = "DEMO";
  }
  return (
    <span>
      {iconType ? (
        <SVG width={IMAGE_WIDTH} height={IMAGE_HEIGHT} name={iconType} />
      ) : null}
      {eventText}
    </span>
  );
};

interface CalendarHeaderProps {
  label: string;
  onNavigate: (navDirection: string) => void;
}

const CalendarHeader = ({ label, onNavigate }: CalendarHeaderProps) => (
  <div style={NAV_CONTAINER}>
    <span style={NAV_TITLE}>{label}</span>
    <span>
      <Button
        style={NAV_BUTTON}
        icon="left"
        onClick={() => onNavigate("PREV")}
      />
      <Button style={NAV_BUTTON} onClick={() => onNavigate("TODAY")}>
        Today
      </Button>
      <Button
        style={NAV_BUTTON}
        icon="right"
        onClick={() => onNavigate("NEXT")}
      />
    </span>
  </div>
);

export interface TaskCalendarProps {
  events: TaskEvent[];
  onHandleSelectEvent: (value: TaskEvent) => void;
}

export const TaskCalendar = ({
  events,
  onHandleSelectEvent,
}: TaskCalendarProps) => {
  return (
    <div style={CALENDAR_CONTAINER}>
      <Calendar
        events={events}
        views={["month"]}
        onHandleSelectEvent={onHandleSelectEvent}
        eventStyleGetter={eventStyleGetter}
        components={{ event: CalendarEvent, toolbar: CalendarHeader }}
      />
    </div>
  );
};

export default TaskCalendar;
