import React, { useRef, useImperativeHandle, useState } from "react";
import { Form } from "@wellth/web-ui";
import { Moment } from "moment-timezone";
import {
  dayIsInThePast,
  dayIsInTheFuture,
  dayIsTodayOrInThePast,
} from "utils/date";
import { CheckInCategory, ReminderInterval, Maybe } from "types/globalTypes";
import { AdminPrivilege } from "constants/adminPrivilege";
import { programTemplatesNotAllowingMedReminders } from "components/EnrollmentForm/EnrollmentPolicy";
import { reminderStateChangeHandler } from "components/EditReminderForm/EditReminderPolicy";
import inputs, { ReminderMethod } from "./inputs";
import Footer from "./components/Footer";

const shouldDisableReminderDeleteButton = ({
  startMoment,
  timezone,
  endMoment,
}: {
  startMoment: Moment;
  timezone: string;
  endMoment?: Moment;
}): boolean => {
  const startDate = startMoment.toDate();
  const startDatePassed = dayIsInThePast(startDate, timezone);

  // if no endDate and reminder is active
  if (!endMoment && startDatePassed) return false;

  const endDate = endMoment.toDate();
  return (
    (startDatePassed && dayIsTodayOrInThePast(endDate, timezone)) ||
    (startDatePassed && dayIsInTheFuture(endDate, timezone))
  );
};

const formatIsDemo = (isDemo: boolean): string => (isDemo ? "True" : "False");
const formatIsRequired = (isRequired: boolean): string =>
  isRequired ? "True" : "False";
const formatEndDate = (endDate: Moment | null): string =>
  endDate ? endDate.format("L") : "";

export interface ReminderProps {
  id: string;
  dueTime: Moment;
  checkInCategory: CheckInCategory;
  interval: ReminderInterval;
  startDate: Moment;
  endDate: Maybe<Moment>;
  windowBeforeMinutes: number;
  windowAfterMinutes: number;
  isDemo: boolean;
  isRequired: boolean;
}

export interface EditReminderFormProps extends Partial<ReminderProps> {
  method: ReminderMethod;
  deleteReminder: (reminderId: string) => void;
  timezone: string;
  deleteLoading?: boolean;
  adminRole: AdminPrivilege;
  programTemplate: string;
}

const EditReminderForm: React.RefForwardingComponent<
  any,
  EditReminderFormProps
> = (
  {
    method,
    timezone,
    deleteReminder,
    id,
    dueTime,
    checkInCategory,
    interval,
    startDate,
    endDate,
    windowAfterMinutes = 180,
    windowBeforeMinutes = 360,
    isDemo,
    isRequired = true,
    deleteLoading = false,
    adminRole,
    programTemplate,
  }: EditReminderFormProps,
  ref,
) => {
  const formRef = useRef(null);
  const [isRequiredOverride, setIsRequiredOverride] = useState(
    programTemplatesNotAllowingMedReminders.includes(programTemplate) &&
      checkInCategory === CheckInCategory.Medication,
  );
  const [formState, setFormState] = useState({
    checkInCategory,
    windowBeforeMinutes,
    windowAfterMinutes,
    isDemo,
    interval,
    startDate,
    endDate,
    dueTime,
    timezone,
    isRequired,
  });

  const isReviewing = method === ReminderMethod.Info;
  const isEditing = method === ReminderMethod.Edit;

  useImperativeHandle(ref, () => ({
    getReminder: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  function handleValueChange(changedValues) {
    const newState = reminderStateChangeHandler(
      formState,
      changedValues,
      programTemplate,
      setIsRequiredOverride,
    );

    setFormState(newState);
  }

  return (
    <>
      <Form
        wrappedComponentRef={formRef}
        onValuesChange={handleValueChange}
        mapPropsToFields={(_props, createFormField) => ({
          checkInCategory: createFormField({
            value: formState.checkInCategory,
          }),
          interval: createFormField({ value: formState.interval }),
          dueTime: createFormField({
            value: isReviewing
              ? formState.dueTime.format("LT")
              : formState.dueTime,
          }),
          startDate: createFormField({
            value: isReviewing
              ? formState.startDate.format("L")
              : formState.startDate,
          }),
          endDate: createFormField({
            value: isReviewing
              ? formatEndDate(formState.endDate)
              : formState.endDate,
          }),
          windowBeforeMinutes: createFormField({
            value: formState.windowBeforeMinutes,
          }),
          windowAfterMinutes: createFormField({
            value: formState.windowAfterMinutes,
          }),
          isRequired: createFormField({
            value: isReviewing
              ? formatIsRequired(formState.isRequired && !isRequiredOverride)
              : formState.isRequired && !isRequiredOverride,
          }),
          isDemo: createFormField({
            value: isReviewing
              ? formatIsDemo(formState.isDemo)
              : formState.isDemo,
          }),
        })}
        inputs={inputs(
          isEditing
            ? dayIsInThePast(formState.startDate.toDate(), formState.timezone)
            : false,
          formState.timezone,
          method,
          isRequiredOverride,
          adminRole,
        )}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 },
        }}
        colValue={12}
      />
      {isEditing ? (
        <Footer
          adminRole={adminRole}
          deleteReminder={() => {
            deleteReminder(id);
          }}
          disabled={shouldDisableReminderDeleteButton({
            startMoment: formState.startDate,
            timezone: formState.timezone,
            endMoment: formState.endDate,
          })}
          loading={deleteLoading}
        />
      ) : null}
    </>
  );
};

export default React.forwardRef(EditReminderForm);
