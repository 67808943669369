import { FormInputProps, FormInputType, OptionProps } from "@wellth/web-ui";
import { AdminPrivilege } from "constants/adminPrivilege";
import { isAdminOrSuperAdmin } from "utils/roles";

export const CONSTANTS = {
  unenrollOnboarding: "Unenroll - Onboarding Not Successful",
  unenrollActivation: "Unenroll - Activation Not Successful",
  unenrollProgramComplete: "Unenroll - Program Complete",
  unenrollAttrition: "Unenroll - Attrition",
  unenrollVoluntaryDeparture: "Unenroll - Voluntary Departure",
  unenrollInvoluntaryDeparture: "Unenroll - Involuntary Departure",
  declined: "Declined",
  ineligble: "Ineligible",
  duplicate: "Duplicate",
  createdInError: "Created in Error",
  closed: "closed",
  other: "Other",
  cantParticipate: "Can't Participate",
  noReason: "No reason given",
  notInterested: "Not interested in using app",
  lifeEvent: "Not a good time (life event)",
  useSomethingElseForAdherence: "Use something else to help with adherence",
  rewardsInsufficient: "Rewards insufficient",
  tooSkeptical: "Too skeptical",
  languageNotSupported: "Language not supported",
  insufficientSupplies: "Insufficient supplies",
  doNotHaveSmartPhone: "Do not have smartphone",
  notTakingMeds: "Not taking meds",
  leavingHealthPlan: "Leaving health plan",
  physicalMentalImpairment: "Physical / mental Impairment",
  deceased: "Deceased",
  underage: "Underage",
  enrolledInAltProgram: "Enrolled in ALT Program",
  enrolledInNonAltProgram: "Enrolled in other non-ALT program",
};

const sharedOptions: OptionProps[] = [
  { title: CONSTANTS.other, value: CONSTANTS.other },
];

export const closedReasons: OptionProps[] = [
  ...sharedOptions,
  { title: "Do not have a smartphone", value: "Do not have a smartphone" },
  {
    title: "Use something else to help with adherence",
    value: "Use something else to help with adherence",
  },
  {
    title: "Just don’t think this is for them",
    value: "Just don’t think this is for them",
  },
  {
    title: "No longer eligible for this Program",
    value: "No longer eligible for this Program",
  },
  {
    title: "Insufficient contact information",
    value: "Insufficient contact information",
  },
];

export const inactiveReasons: OptionProps[] = [
  { title: CONSTANTS.unenrollOnboarding, value: CONSTANTS.unenrollOnboarding },
  { title: CONSTANTS.unenrollActivation, value: CONSTANTS.unenrollActivation },
  {
    title: CONSTANTS.unenrollProgramComplete,
    value: CONSTANTS.unenrollProgramComplete,
  },
  { title: CONSTANTS.unenrollAttrition, value: CONSTANTS.unenrollAttrition },
  {
    title: CONSTANTS.unenrollVoluntaryDeparture,
    value: CONSTANTS.unenrollVoluntaryDeparture,
  },
  {
    title: CONSTANTS.unenrollInvoluntaryDeparture,
    value: CONSTANTS.unenrollInvoluntaryDeparture,
  },
];

export const enrolledReasons: OptionProps[] = [
  { title: CONSTANTS.unenrollOnboarding, value: CONSTANTS.unenrollOnboarding },
];

export const activeReasons: OptionProps[] = [
  {
    title: CONSTANTS.unenrollProgramComplete,
    value: CONSTANTS.unenrollProgramComplete,
  },
  { title: CONSTANTS.unenrollAttrition, value: CONSTANTS.unenrollAttrition },
  {
    title: CONSTANTS.unenrollVoluntaryDeparture,
    value: CONSTANTS.unenrollVoluntaryDeparture,
  },
  {
    title: CONSTANTS.unenrollInvoluntaryDeparture,
    value: CONSTANTS.unenrollInvoluntaryDeparture,
  },
];

export const onboardedReasons: OptionProps[] = [
  { title: CONSTANTS.unenrollActivation, value: CONSTANTS.unenrollActivation },
];

export const unsuccessfulOnboarding: OptionProps[] = [
  ...sharedOptions,
  { title: "Do not have a smartphone", value: "Do not have a smartphone" },
  { title: "Phone doesn't support app", value: "Phone doesn't support app" },
  {
    title: "Couldn't download app in Play/App store",
    value: "Couldn't download app in Play/App store",
  },
  {
    title: "Can't login / Didn't get SMS login",
    value: "Can't login / Didn't get SMS login",
  },
  { title: "Couldn't submit demo task", value: "Couldn't submit demo task" },
  { title: "Other technical issue", value: "Othere technical issue" },
  { title: "Disconnected phone call", value: "Disconnected phone call" },
  {
    title: "Other non-technical / user issue",
    value: "Other non-technical / user issue",
  },
  { title: "Language issue", value: "Language issue" },
];

export const unsuccessfulActivation: OptionProps[] = [
  ...sharedOptions,
  { title: "Unknown Attrition", value: "Unknown Attrition" },
  { title: "Technical Issue(s)", value: "Technical Issue(s)" },
  {
    title: "Non-technical or user issue",
    value: "Non-technical or user issue",
  },
  { title: "No longer interested", value: "No longer interested" },
];

export const attrition: OptionProps[] = [
  ...sharedOptions.filter((v) => v.title === "Other"),
  { title: "Unknown Attrition", value: "Unknown Attrition" },
  { title: "Technical Issue(s)", value: "Technical Issue(s)" },
  {
    title: "Non-technical or user issue",
    value: "Non-technical or user issue",
  },
];

export const voluntaryDeparture: OptionProps[] = [
  ...sharedOptions,
  {
    title: "Use something else to help with adherence",
    value: "Use something else to help with adherence",
  },
  { title: "No longer interested", value: "No longer interested" },
  { title: "Technical Issue(s)", value: "Technical Issue(s)" },
];

export const involuntaryDeparture: OptionProps[] = [
  { title: "Lost or stolen phone", value: "Lost or stolen phone" },
  {
    title: "Care plan no longer supported",
    value: "Care plan no longer supported",
  },
  { title: "Technical Issue(s)", value: "Technical Issue(s)" },
  {
    title: "No longer eligible - per Customer",
    value: "No longer eligible - per Customer",
  },
  {
    title: "No longer eligible - left Customer care",
    value: "No longer eligible - left Customer care",
  },
  {
    title: "Member Expired",
    value: "Member Expired",
  },
  {
    title: "Admitted into a facility/hospital",
    value: "Admitted into a facility/hospital",
  },
  ...sharedOptions.filter((v) => v.title === "Other"),
];

export const declinedOptions: OptionProps[] = [
  { title: CONSTANTS.tooSkeptical, value: CONSTANTS.tooSkeptical },
  {
    title: CONSTANTS.rewardsInsufficient,
    value: CONSTANTS.rewardsInsufficient,
  },
  {
    title: CONSTANTS.useSomethingElseForAdherence,
    value: CONSTANTS.useSomethingElseForAdherence,
  },
  { title: CONSTANTS.lifeEvent, value: CONSTANTS.lifeEvent },
  { title: CONSTANTS.notInterested, value: CONSTANTS.notInterested },
  { title: CONSTANTS.noReason, value: CONSTANTS.noReason },
  ...sharedOptions,
];

export const cantParticipateOptions: OptionProps[] = [
  {
    title: CONSTANTS.languageNotSupported,
    value: CONSTANTS.languageNotSupported,
  },
  {
    title: CONSTANTS.insufficientSupplies,
    value: CONSTANTS.insufficientSupplies,
  },
  {
    title: CONSTANTS.doNotHaveSmartPhone,
    value: CONSTANTS.doNotHaveSmartPhone,
  },
  {
    title: CONSTANTS.notTakingMeds,
    value: CONSTANTS.notTakingMeds,
  },
  {
    title: CONSTANTS.leavingHealthPlan,
    value: CONSTANTS.leavingHealthPlan,
  },
  {
    title: CONSTANTS.physicalMentalImpairment,
    value: CONSTANTS.physicalMentalImpairment,
  },
  { title: CONSTANTS.deceased, value: CONSTANTS.deceased },
  {
    title: CONSTANTS.enrolledInAltProgram,
    value: CONSTANTS.enrolledInAltProgram,
  },
  {
    title: CONSTANTS.enrolledInNonAltProgram,
    value: CONSTANTS.enrolledInNonAltProgram,
  },
  ...sharedOptions,
];

export const ineligbleOptions: OptionProps[] = [
  { title: CONSTANTS.underage, value: CONSTANTS.underage },
];

const dropDownStyles = {
  height: "1rem",
  width: "20rem",
  paddingBottom: "1rem",
};

const setProperties = (
  { radio, reason, reasonDetail },
  showReason: boolean,
  showReasonDetail: boolean,
  reasonRequired: boolean,
  reasonDetailRequired: boolean,
  noteRequired: boolean,
  showType: boolean,
): FormInputProps[] => [
  {
    type: FormInputType.Radio,
    title: "Type",
    key: "type",
    options: radio,
    disabled: !showType,
  },
  {
    type: FormInputType.Dropdown,
    title: "Reason",
    key: "reason",
    placeholder: "Select a reason",
    disabled: !showReason,
    style: dropDownStyles,
    options: reason,
    decoratorOptions: {
      rules: [{ required: reasonRequired, message: "Please select a reason." }],
    },
  },
  {
    type: FormInputType.Dropdown,
    title: "Reason Detail",
    key: "reasonDetail",
    placeholder: "Selete a reason detail",
    disabled: !showReasonDetail,
    style: dropDownStyles,
    options: reasonDetail,
    decoratorOptions: {
      rules: [
        {
          required: reasonDetailRequired,
          message: "Please select a reason detail.",
        },
      ],
    },
  },
  {
    type: FormInputType.TextArea,
    title: "Add note",
    key: "note",
    autoSize: { minRows: 2 },
    style: {
      minWidth: dropDownStyles.width,
    },
    decoratorOptions: {
      rules: [
        {
          required: noteRequired,
          message: "Please provide a note with more detail.",
        },
      ],
    },
  },
];

const setClosedOptions = (reason: string) => {
  switch (reason) {
    case CONSTANTS.declined:
      return declinedOptions;
    case CONSTANTS.duplicate:
      return [];
    case CONSTANTS.cantParticipate:
      return cantParticipateOptions;
    case CONSTANTS.ineligble:
      return ineligbleOptions;
    case CONSTANTS.createdInError:
      return [];
    default:
      return [];
  }
};

const openReasons = [
  {
    title: CONSTANTS.declined,
    value: CONSTANTS.declined,
  },
  {
    title: CONSTANTS.duplicate,
    value: CONSTANTS.duplicate,
  },
  {
    title: CONSTANTS.cantParticipate,
    value: CONSTANTS.cantParticipate,
  },
  {
    title: CONSTANTS.ineligble,
    value: CONSTANTS.ineligble,
  },
  {
    title: CONSTANTS.createdInError,
    value: CONSTANTS.createdInError,
  },
];

const openOptions = (reason: string, reasonOptions?) => ({
  radio: [
    {
      label: "Change to Closed",
      value: "closed",
    },
  ],
  reason: reasonOptions,
  reasonDetail: setClosedOptions(reason),
});

const closedOptions = {
  radio: [
    {
      label: "Change to Open",
      value: "open",
    },
  ],
  reason: [
    {
      title: "Re-open",
      value: "Re-open",
    },
  ],
  reasonDetail: [],
};

const setInactiveOptions = (reason: string) => {
  switch (reason) {
    case CONSTANTS.unenrollOnboarding:
      return unsuccessfulOnboarding;
    case CONSTANTS.unenrollActivation:
      return unsuccessfulActivation;
    case CONSTANTS.unenrollAttrition:
      return attrition;
    case CONSTANTS.unenrollVoluntaryDeparture:
      return voluntaryDeparture;
    case CONSTANTS.unenrollInvoluntaryDeparture:
      return involuntaryDeparture;
    default:
      return [];
  }
};

const activeOptions = (reason: string, reasonOptions?) => ({
  radio: [{ label: "Change to Inactive", value: "inactive" }],
  reason: reasonOptions,
  reasonDetail: setInactiveOptions(reason),
});

export default (
  status: string,
  showReason: boolean,
  showReasonDetail: boolean,
  reason: string,
  reasonRequired: boolean,
  reasonDetailRequired: boolean,
  noteRequired: boolean,
  adminRole: AdminPrivilege,
) => {
  switch (status) {
    case "open":
      return setProperties(
        openOptions(reason, openReasons),
        showReason,
        showReasonDetail,
        reasonRequired,
        reasonDetailRequired,
        noteRequired,
        true,
      );
    case "closed":
      return setProperties(
        closedOptions,
        showReason,
        showReasonDetail,
        reasonRequired,
        reasonDetailRequired,
        noteRequired,
        true,
      );
    case "active":
      return setProperties(
        activeOptions(reason, activeReasons),
        showReason,
        showReasonDetail,
        reasonRequired,
        reasonDetailRequired,
        noteRequired,
        isAdminOrSuperAdmin(adminRole),
      );
    case "enrolled":
      return setProperties(
        activeOptions(reason, enrolledReasons),
        showReason,
        showReasonDetail,
        reasonRequired,
        reasonDetailRequired,
        noteRequired,
        isAdminOrSuperAdmin(adminRole),
      );
    case "inactive":
      return [];
    case "onboarded":
      return setProperties(
        activeOptions(reason, onboardedReasons),
        showReason,
        showReasonDetail,
        reasonRequired,
        reasonDetailRequired,
        noteRequired,
        isAdminOrSuperAdmin(adminRole),
      );
    default:
      return [];
  }
};
