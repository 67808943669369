import React from "react";
import { Column, GridRow, message } from "@wellth/web-ui";
import { LoadingScreen } from "@wellth/ui";
import { find, get } from "lodash";
import moment from "moment-timezone";
import * as grid from "constants/grid";
import { MEMBER_PROFILE_REFETCH } from "utils";
import * as styles from "constants/styles";
import { AdminPrivilege } from "constants/adminPrivilege";
import KeyMemberInfo from "components/KeyMemberInfo";
import Info from "components/PersonInformationCard";
import TabCard from "components/TabCard";
import { Rewards } from "containers/Rewards";
import Description from "components/Description";
import { AdherenceCard } from "components/Adherence";
import { ConditionsCard } from "components/Conditions";
import { OtherActions } from "containers/OtherActions";
import { PatientChat } from "containers/PatientChat";
import { PatientNotes } from "containers/PatientNotes";
import { useMemberQuery, useUpdateMemberByIdMutation } from "hooks/graphql";
import { ReminderMethod } from "components/EditReminderForm/inputs";
import { isAdminOrSuperAdmin, isCallCenterAdmin } from "utils/roles";
import {
  MemberProfileAdherenceFragment,
  MemberStatusValue,
  PauseStatus,
  TypeOfEnrollmentOptions,
} from "types/globalTypes";
import { IdentityTag } from "containers/IdentityTag";
import { ActivityContainer } from "containers/Activity";
import { Reminders } from "./components/Reminders";
import TaskCalendarContainer, {
  AppointmentType,
  TaskCalendarEvent,
} from "./components/TaskCalendar";
import MixpanelContainer from "../Mixpanel";
import * as memberProfileStyle from "./styles";
import * as constants from "./constants";

interface Primary {
  isPrimary: boolean;
}

const sortByPrimary = (multipleInputs) =>
  multipleInputs.sort((a) => (a.isPrimary ? -1 : 1));

const findMemberName = <T extends Primary>(objects: T[]): T | undefined => {
  const primaryName = find(objects, ({ isPrimary }) => isPrimary);
  if (!primaryName) {
    return objects.length > 0 ? objects[0] : undefined;
  }
  return primaryName;
};

const calculatePercentOptionalRemindersAdherent = (
  adherenceSummary: MemberProfileAdherenceFragment,
): number => {
  const {
    totalValidCheckInCount,
    requiredValidCheckInCount,
    totalReminderCount,
    requiredReminderCount,
  } = adherenceSummary;
  const numberOfTotalReminders = Number(totalReminderCount);
  const numberOfRequiredReminders = Number(requiredReminderCount);
  if (numberOfTotalReminders - numberOfRequiredReminders <= 0) {
    return NaN;
  }

  const numberOfTotalCheckIns = Number(totalValidCheckInCount);
  const numberOfRequiredCheckIns = Number(requiredValidCheckInCount);

  return (
    ((numberOfTotalCheckIns - numberOfRequiredCheckIns) /
      (numberOfTotalReminders - numberOfRequiredReminders)) *
    100
  );
};

export interface TestPatient {
  isLocalNotificationEnabled: boolean;
  isReminderMessagingEnabled: boolean;
  isProductTourRecurring: boolean;
}

export interface StatusChange {
  status: string;
  programCode: string;
  shortId: string;
  adminRole: string;
}

export interface MemberProfileProps {
  id: string;
  adminRole: AdminPrivilege;
  adminId: string;
  routeCheckIn: (event: TaskCalendarEvent, memberTimezone: string) => void;
  routeAppointment: (event: AppointmentType, memberTimezone: string) => void;
  routeTestPatient: (testPatient: TestPatient) => void;
  routeStatusChange: (statusChange: StatusChange) => void;
  routeDeleteMember: () => void;
  routeInfoModal: () => void;
  routeRewardModal: (
    periodEnd: Date,
    periodReward: number,
    periodSum: number,
    isAdmin: boolean,
    rewardPeriodId: string,
    periodStart: Date,
    settleDate: Date,
    pauseStatus: PauseStatus,
    programCode: string,
  ) => void;
  routeCreateReminder: (
    memberTimezone: string,
    programTemplate: string,
  ) => void;
  routeEditReminder: (
    reminderId: string,
    memberTimezone: string,
    programTemplate: string,
  ) => void;
  routeReminderInfo: (
    reminderId: string,
    memberTimezone: string,
    programTemplate: string,
  ) => void;
  routePauseModal: (pauseStatus: string) => void;
  routeEditPauseModal: (
    pauseDetails: { id: string; reason: string; note: string },
  ) => void;
  routeReOpenProspect: (programCode: string, shortId: string) => void;
}

export const MemberProfile: React.FC<MemberProfileProps> = ({
  adminRole,
  adminId,
  id,
  routeCheckIn,
  routeAppointment,
  routeTestPatient,
  routeStatusChange,
  routeDeleteMember,
  routeInfoModal,
  routeRewardModal,
  routeCreateReminder,
  routeEditReminder,
  routeReminderInfo,
  routePauseModal,
  routeEditPauseModal,
  routeReOpenProspect,
}) => {
  const {
    loading: memberLoading,
    error,
    data: {
      member: {
        description = null,
        isTestMember = null,
        reminders = [],
        isReminderEditingOpen = null,
        isRestfulMember = null,
        isReminderMessagingEnabled = null,
        isLocalNotificationEnabled = null,
        isProductTourRecurring = null,
        pauseStatus = null,
        pauseDetails = null,
        renderCalendarView = [],
        appointments = { nodes: [] },
        createdAt = null,
        latestTimezone = null,
        enrolledBy = null,
        typeOfEnrollment = null,
        person: {
          names = [],
          addresses = [],
          gender = "",
          birthDate = "",
          emailAddresses = [],
          phoneNumbers = [],
          shortId = "",
          primaryLanguage = "",
          primaryTimezone = "",
        } = {},
        currentOrNextRewardPeriodBalance: {
          periodSum = null,
          periodReward = null,
          periodEnd = null,
          rewardPeriodId = null,
          periodStart = null,
          settleDate = null,
        } = {},
        program: { programCode = "", programTemplate = "" } = {},
        last30 = {
          __typename: "AdherenceSummary",
          requiredReminderCount: null,
          totalReminderCount: null,
          requiredValidCheckInCount: null,
          totalValidCheckInCount: null,
          percentRequiredRemindersAdherent: null,
          percentTotalRemindersAdherent: null,
        },
        last7 = {
          __typename: "AdherenceSummary",
          requiredReminderCount: null,
          totalReminderCount: null,
          requiredValidCheckInCount: null,
          totalValidCheckInCount: null,
          percentRequiredRemindersAdherent: null,
          percentTotalRemindersAdherent: null,
        },
        total = {
          __typename: "AdherenceSummary",
          requiredReminderCount: null,
          totalReminderCount: null,
          requiredValidCheckInCount: null,
          totalValidCheckInCount: null,
          percentRequiredRemindersAdherent: null,
          percentTotalRemindersAdherent: null,
        },
        status = null,
        wellthId = null,
        overallScore,
        externalId = null,
        enrollmentDate = null,
      } = {},
    } = {},
  } = useMemberQuery({
    variables: {
      id,
      last7StartDate: constants.last7StartDate,
      last30StartDate: constants.last30StartDate,
      totalStartDate: constants.totalStartDate,
      today: constants.today,
      endDate: constants.endDate,
    },
    onError: () => message.error("Unable to fetch member"),
  });
  const [updateMember] = useUpdateMemberByIdMutation();

  if (memberLoading) return <LoadingScreen showLoadingIndicator />;

  const { timezoneName: timezone = "" } = latestTimezone || {};
  const { firstName: primaryFirstName = "", lastName: primaryLastName = "" } =
    findMemberName(names) || {};
  const {
    id: pauseId,
    reason,
    startDate,
    endDate,
    note,
    totalPausedDays,
    previousReason,
    previousStartDate,
    previousEndDate,
  } = pauseDetails || {};
  const memberStatus = get(status, "value", "[UNKNOWN]");
  return (
    <>
      {error ? <div style={memberProfileStyle.DISABLE_SCREEN_STYLES} /> : null}
      <div style={styles.CONTAINER_STYLES}>
        <>
          <GridRow
            style={styles.ROW_STYLES}
            type={grid.GRID_TYPE}
            justify={grid.GRID_JUSTIFY}
            gutter={grid.GUTTER}
          >
            <Column
              xs={grid.COLUMN_FULL_WIDTH}
              sm={grid.COLUMN_FULL_WIDTH}
              md={grid.COLUMN_THRID_WIDTH}
              lg={grid.COLUMN_THRID_WIDTH}
              xl={grid.COLUMN_THRID_WIDTH}
            >
              <div style={styles.CONTENT_STYLES}>
                <KeyMemberInfo
                  fullName={`${primaryFirstName} ${primaryLastName}`}
                  shortId={shortId}
                  isTestMember={isTestMember}
                  title="Member"
                  programCode={programCode}
                  status={memberStatus}
                  adminRole={adminRole}
                  handleTestMember={() =>
                    routeTestPatient({
                      isLocalNotificationEnabled,
                      isReminderMessagingEnabled,
                      isProductTourRecurring,
                    })
                  }
                  handleChangeStatus={
                    memberStatus !== MemberStatusValue.Inactive
                      ? () => {
                          routeStatusChange({
                            status: memberStatus,
                            shortId,
                            programCode,
                            adminRole,
                          });
                        }
                      : null
                  }
                  handleDeleteMember={routeDeleteMember}
                  handleReOpeningProspect={
                    memberStatus === MemberStatusValue.Inactive
                      ? () => {
                          routeReOpenProspect(programCode, shortId);
                        }
                      : null
                  }
                />
              </div>

              <div style={styles.CONTENT_STYLES}>
                <IdentityTag wellthId={wellthId} />
              </div>

              <div style={styles.CONTENT_STYLES}>
                <ConditionsCard
                  externalId={externalId}
                  programCode={programCode}
                />
              </div>

              <div style={styles.CONTENT_STYLES}>
                <AdherenceCard
                  optionalRemindersAdherence={{
                    last30: calculatePercentOptionalRemindersAdherent(last30),
                    last7: calculatePercentOptionalRemindersAdherent(last7),
                    total: calculatePercentOptionalRemindersAdherent(total),
                  }}
                  requiredRemindersAdherence={{
                    last30: Number(last30.percentRequiredRemindersAdherent),
                    last7: Number(last7.percentRequiredRemindersAdherent),
                    total: Number(total.percentRequiredRemindersAdherent),
                  }}
                  allTasksRemindersAdherence={{
                    last30: Number(last30.percentTotalRemindersAdherent),
                    last7: Number(last7.percentTotalRemindersAdherent),
                    total: Number(total.percentTotalRemindersAdherent),
                  }}
                />
              </div>
              <div style={styles.CONTENT_STYLES}>
                <Info
                  personProfile={{
                    language: primaryLanguage,
                    phoneNumbers: sortByPrimary(
                      phoneNumbers.map(({ phoneNumber, isPrimary }) => ({
                        phoneNumber,
                        isPrimary,
                      })),
                    ),
                    emailAddresses: sortByPrimary(
                      emailAddresses.map(({ emailAddress, isPrimary }) => ({
                        emailAddress,
                        isPrimary,
                      })),
                    ),
                    addresses: sortByPrimary(
                      addresses.map(
                        ({ street, street2, city, state, zip, isPrimary }) => ({
                          street,
                          street2,
                          city,
                          state,
                          zip,
                          isPrimary,
                        }),
                      ),
                    ),
                    personSince: moment(createdAt).format("LL"),
                    gender,
                    birthdate: birthDate,
                  }}
                  overallScore={overallScore}
                  localTime={moment.tz(timezone).format("h:mm A (z)")}
                  type="Member"
                  onInfoModalClick={routeInfoModal}
                  isSelfServe={
                    typeOfEnrollment === TypeOfEnrollmentOptions.SelfServe
                  }
                  enroller={enrolledBy}
                />
              </div>
              <div style={styles.CONTENT_STYLES}>
                <Rewards
                  memberId={id}
                  onRewardModalClick={() =>
                    routeRewardModal(
                      moment(periodEnd).toDate(),
                      periodReward,
                      periodSum,
                      isAdminOrSuperAdmin(adminRole),
                      rewardPeriodId,
                      moment(periodStart).toDate(),
                      moment(settleDate).toDate(),
                      pauseStatus,
                      programCode,
                    )
                  }
                  nextPayoutDate={moment(settleDate).toDate()}
                  nextPayOutAmount={periodSum}
                  maximumCurrentPayout={periodReward}
                />
              </div>
              <div style={styles.CONTENT_STYLES}>
                <Reminders
                  reminders={reminders}
                  programTemplate={programTemplate}
                  isReminderEditingOpen={isReminderEditingOpen}
                  onReminderModalClick={({ reminderId, method }) => {
                    switch (method) {
                      case ReminderMethod.Edit:
                        routeEditReminder(
                          reminderId,
                          timezone,
                          programTemplate,
                        );
                        break;
                      case ReminderMethod.Create:
                        routeCreateReminder(timezone, programTemplate);
                        break;
                      case ReminderMethod.Info:
                        routeReminderInfo(
                          reminderId,
                          timezone,
                          programTemplate,
                        );
                        break;
                      default:
                        throw new Error(`Invalid Method provided ${method}`);
                    }
                  }}
                  handleReminderLockChange={async () => {
                    await updateMember({
                      variables: {
                        id,
                        memberPatch: {
                          isReminderEditingOpen: !isReminderEditingOpen,
                          person: {},
                        },
                      },
                      refetchQueries: MEMBER_PROFILE_REFETCH(id),
                      awaitRefetchQueries: true,
                    });
                  }}
                />
              </div>
              <div style={styles.CONTENT_STYLES}>
                <OtherActions
                  canEditPause={
                    !isCallCenterAdmin(adminRole) && pauseStatus !== "ACTIVE"
                  }
                  routeEditPauseModal={() =>
                    routeEditPauseModal({ id: pauseId, reason, note })
                  }
                  isRestfulMember={isRestfulMember}
                  pauseStatus={pauseStatus}
                  reason={reason}
                  startDate={startDate}
                  endDate={endDate}
                  totalPausedDays={totalPausedDays}
                  previousReason={previousReason}
                  previousStartDate={previousStartDate}
                  previousEndDate={previousEndDate}
                  id={id}
                  onButtonClick={() => routePauseModal(pauseStatus)}
                />
              </div>
            </Column>
            <Column
              xs={grid.COLUMN_FULL_WIDTH}
              sm={grid.COLUMN_FULL_WIDTH}
              md={grid.COLUMN_HALF_WIDTH}
              lg={grid.COLUMN_HALF_WIDTH}
              xl={grid.COLUMN_HALF_WIDTH}
            >
              <div style={memberProfileStyle.CALENDAR_STYLES}>
                <TabCard
                  tabs={[
                    { key: "calendar", tab: "Calendar" },
                    { key: "activity", tab: "Activity" },
                  ]}
                  activeIndex={0}
                >
                  {({ key }) => {
                    switch (key) {
                      case "calendar":
                        return (
                          <TaskCalendarContainer
                            enrollmentDate={enrollmentDate}
                            primaryTimezone={primaryTimezone}
                            appointments={appointments.nodes}
                            renderCalendarView={renderCalendarView}
                            routeCheckIn={routeCheckIn}
                            routeAppointment={routeAppointment}
                          />
                        );
                      case "activity":
                        return (
                          <>
                            <MixpanelContainer wellthId={wellthId} />
                            <ActivityContainer
                              wellthId={wellthId}
                              timezone={primaryTimezone}
                            />
                          </>
                        );
                      default:
                        return null;
                    }
                  }}
                </TabCard>
              </div>
            </Column>
            <Column
              xs={grid.COLUMN_FULL_WIDTH}
              sm={grid.COLUMN_FULL_WIDTH}
              md={grid.COLUMN_THRID_WIDTH}
              lg={grid.COLUMN_THRID_WIDTH}
              xl={grid.COLUMN_THRID_WIDTH}
            >
              <div style={styles.CONTENT_STYLES}>
                <PatientChat patientId={id} adminId={adminId} />
              </div>
              <div style={styles.CONTENT_STYLES}>
                <PatientNotes patientId={id} adminId={adminId} />
              </div>
              <div style={styles.CONTENT_STYLES}>
                <Description
                  descriptionText={description}
                  onClick={async (personDescription: string): Promise<void> => {
                    await updateMember({
                      variables: {
                        id,
                        memberPatch: {
                          description: personDescription,
                        },
                      },
                      refetchQueries: MEMBER_PROFILE_REFETCH(id),
                      awaitRefetchQueries: true,
                    });
                  }}
                />
              </div>
            </Column>
          </GridRow>
        </>
      </div>
    </>
  );
};

export default MemberProfile;
