import React from "react";
import { Form, Button, FormInputType, FormInputProps } from "@wellth/web-ui";
import { isSuperAdmin } from "utils/roles";
import { AdminPrivilege } from "constants/adminPrivilege";
import styles from "./style";

export interface FormValues {
  firstName: string;
  lastName: string;
  birthDate: string;
  phoneNumber: string;
  emailAddress: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  programCode: string;
  isTestUser?: boolean;
  externalId: string;
}

const getInputs = (
  programs: ProgramOptions[],
  adminRole: AdminPrivilege,
): FormInputProps[] =>
  [
    {
      type: FormInputType.Text,
      title: "First Name",
      key: "firstName",
      decoratorOptions: {
        rules: [{ message: "First Name is required", required: true }],
      },
    },
    {
      type: FormInputType.Text,
      title: "Last Name",
      key: "lastName",
      decoratorOptions: {
        rules: [{ message: "Last Name is required", required: true }],
      },
    },
    {
      type: FormInputType.Mask,
      title: "Birthday",
      key: "birthDate",
      placeholder: "DD-MM-YYYY",
      mask: "99-99-9999",
      decoratorOptions: {
        rules: [
          {
            pattern: /(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-([12]\d{3})/,
            message: "A valid birthdate is required",
          },
          { message: "Birthdate is required", required: true },
        ],
      },
      style: { height: "2.1875rem", width: "100%" },
    },
    {
      type: FormInputType.Mask,
      title: "Phone Number",
      key: "phoneNumber",
      mask: "999-999-9999",
      decoratorOptions: {
        rules: [{ message: "Phone number is required", required: true }],
      },
      style: { height: "2.1875rem", width: "100%" },
    },
    { type: FormInputType.Email, title: "Email", key: "emailAddress" },
    {
      type: FormInputType.Text,
      title: "Address 1",
      key: "street",
      style: { height: "2.1875rem", width: "100%" },
    },
    {
      type: FormInputType.Text,
      title: "Address 2",
      key: "street2",
      style: { height: "2.1875rem", width: "100%" },
    },
    {
      type: FormInputType.Text,
      title: "City",
      key: "city",
      style: { height: "2.1875rem", width: "100%" },
    },
    {
      type: FormInputType.Text,
      title: "State",
      key: "state",
      style: { height: "2.1875rem", width: "100%" },
    },
    {
      type: FormInputType.Text,
      title: "Zipcode",
      key: "zip",
      style: { height: "2.1875rem", width: "100%" },
    },
    {
      type: FormInputType.Dropdown,
      title: "Program",
      key: "programCode",
      options: programs,
      style: { height: "1rem", paddingBottom: "2rem" },
      decoratorOptions: {
        rules: [{ message: "Program is required", required: true }],
      },
      placeholder: "Choose a program",
    },
    {
      type: FormInputType.Text,
      title: "External Id",
      key: "externalId",
      style: { height: "2.1875rem", width: "100%" },
      decoratorOptions: {
        rules: [{ message: "External Id is required", required: true }],
      },
    },
    {
      type: FormInputType.Switch,
      title: "Is test user?",
      key: "isTestUser",
      decoratorOptions: { valuePropName: "checked" },
    },
  ].filter(({ key }) => {
    if (isSuperAdmin(adminRole)) {
      return true;
    }
    return key !== "isTestUser";
  }) as FormInputProps[];

export interface CreateProspectProps {
  programs: ProgramOptions[];
  handleSubmit: (values: FormValues) => void;
  adminRole?: AdminPrivilege;
}

interface ProgramOptions {
  title: string;
  value: string;
}

export const CreateProspectForm: React.FC<CreateProspectProps> = ({
  programs,
  handleSubmit,
  adminRole,
}: CreateProspectProps) => (
  <Form
    inputs={getInputs(programs, adminRole)}
    submitButton={(_, form) => (
      <div style={styles.button}>
        <Button
          type="primary"
          onClick={() => {
            form.validateFieldsAndScroll((err) => {
              if (err) {
                return;
              }
              handleSubmit(form.getFieldsValue() as FormValues);
            });
          }}
        >
          Submit
        </Button>
      </div>
    )}
  />
);

export default CreateProspectForm;
