import React from "react";
import { Card, Button, Icon } from "@wellth/web-ui";
import moment from "moment-timezone";
import { today } from "containers/MemberProfile/constants";
import CARD_STYLES from "../styles";

const BODY_STYLE: React.CSSProperties = {
  ...CARD_STYLES.body,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const BUTTON_STYLE: React.CSSProperties = {
  margin: 5,
};

const ICON_SIZE: React.CSSProperties = {
  fontSize: "1.25rem",
};

const TITLE_STYLE: React.CSSProperties = {
  fontWeight: "bold",
};

// pause status with or without previous pause
const ACTIVE = "ACTIVE";
const ACTIVE_WITH_PREVIOUS_PAUSE = "ACTIVE_WITH_PREVIOUS_PAUSE";
const PAUSING = "PAUSING";
const PAUSING_WITH_PREVIOUS_PAUSE = "PAUSING_WITH_PREVIOUS_PAUSE";
const PAUSED = "PAUSED";
const PAUSED_WITH_PREVIOUS_PAUSE = "PAUSED_WITH_PREVIOUS_PAUSE";
const UNPAUSING = "UNPAUSING";
const UNPAUSING_WITH_PREVIOUS_PAUSE = "UNPAUSING_WITH_PREVIOUS_PAUSE";
// pause detail title options
const CURRENT_STATUS = "CURRENT_STATUS";
const CURRENT_REASON = "CURRENT_REASON";
const CURRENT_START_DATE = "CURRENT_START_DATE";
const PREVIOUS_REASON = "PREVIOUS_REASON";
const PREVIOUS_START_DATE = "PREVIOUS_START_DATE";
const PREVIOUS_END_DATE = "PREVIOUS_END_DATE";
const TOTAL_PAUSED_DAYS = "TOTAL_PAUSED_DAYS";
const CURRENT_PAUSED_DAYS = "CURRENT_PAUSED_DAYS";

const pauseStatusDetailList = [
  {
    status: ACTIVE,
    details: [CURRENT_STATUS],
  },
  {
    status: ACTIVE_WITH_PREVIOUS_PAUSE,
    details: [
      CURRENT_STATUS,
      PREVIOUS_REASON,
      PREVIOUS_START_DATE,
      PREVIOUS_END_DATE,
      TOTAL_PAUSED_DAYS,
    ],
  },
  {
    status: PAUSING,
    details: [CURRENT_STATUS, CURRENT_REASON],
  },
  {
    status: PAUSING_WITH_PREVIOUS_PAUSE,
    details: [
      CURRENT_STATUS,
      CURRENT_REASON,
      PREVIOUS_REASON,
      PREVIOUS_START_DATE,
      PREVIOUS_END_DATE,
      TOTAL_PAUSED_DAYS,
    ],
  },
  {
    status: PAUSED,
    details: [
      CURRENT_STATUS,
      CURRENT_REASON,
      CURRENT_START_DATE,
      CURRENT_PAUSED_DAYS,
      TOTAL_PAUSED_DAYS,
    ],
  },
  {
    status: PAUSED_WITH_PREVIOUS_PAUSE,
    details: [
      CURRENT_STATUS,
      CURRENT_REASON,
      CURRENT_START_DATE,
      CURRENT_PAUSED_DAYS,
      TOTAL_PAUSED_DAYS,
    ],
  },
  {
    status: UNPAUSING,
    details: [
      CURRENT_STATUS,
      CURRENT_REASON,
      CURRENT_START_DATE,
      CURRENT_PAUSED_DAYS,
      TOTAL_PAUSED_DAYS,
    ],
  },
  {
    status: UNPAUSING_WITH_PREVIOUS_PAUSE,
    details: [
      CURRENT_STATUS,
      CURRENT_REASON,
      CURRENT_START_DATE,
      CURRENT_PAUSED_DAYS,
      TOTAL_PAUSED_DAYS,
    ],
  },
];

interface PauseDetailsProps {
  pauseStatus: string;
  reason?: string;
  startDate?: string;
  endDate?: string;
  totalPausedDays?: number;
  previousReason?: string;
  previousStartDate?: string;
  previousEndDate?: string;
}

const renderFragmentWithTitle = (title, content) => (
  <>
    <div style={TITLE_STYLE}>{title}: </div>
    <>{content}</>
  </>
);

const PauseDetails: React.FC<PauseDetailsProps> = ({
  pauseStatus,
  reason,
  startDate,
  endDate,
  totalPausedDays,
  previousReason,
  previousStartDate,
  previousEndDate,
}: PauseDetailsProps) => {
  const adjustedPauseStatus = previousReason
    ? `${pauseStatus}_WITH_PREVIOUS_PAUSE`
    : pauseStatus;

  const currentFromPauseDate = endDate ? moment(endDate) : moment(today);

  const pauseDetailFragments = {
    CURRENT_STATUS: <div style={TITLE_STYLE}>Member is {pauseStatus}</div>,
    CURRENT_REASON: renderFragmentWithTitle("Current Pause Reason", reason),
    CURRENT_START_DATE: renderFragmentWithTitle(
      "Current Pause Start Date",
      startDate,
    ),
    CURRENT_END_DATE: renderFragmentWithTitle(
      "Current Pause End Date",
      endDate,
    ),
    CURRENT_PAUSED_DAYS: renderFragmentWithTitle(
      "Current Pause Days",
      currentFromPauseDate.diff(moment(startDate), "day") + 1,
    ),
    TOTAL_PAUSED_DAYS: renderFragmentWithTitle(
      "Total Paused Days",
      totalPausedDays,
    ),
    PREVIOUS_REASON: renderFragmentWithTitle(
      "Previous Pause Reason",
      previousReason,
    ),
    PREVIOUS_START_DATE: renderFragmentWithTitle(
      "Previous Start Date",
      previousStartDate,
    ),
    PREVIOUS_END_DATE: renderFragmentWithTitle(
      "Previous End Date",
      previousEndDate,
    ),
  };

  // default to active list details
  const pauseStatusDetail =
    pauseStatusDetailList.find((item) => item.status === adjustedPauseStatus) ||
    pauseStatusDetailList.find((item) => item.status === ACTIVE);

  const pauseDetails = pauseStatusDetail.details;

  return (
    <>
      {pauseDetails.map((detail, index) => (
        <div key={detail + index}>{pauseDetailFragments[detail]}</div>
      ))}
    </>
  );
};

const pauseButtonTitle = {
  ACTIVE: "PAUSE MEMBER",
  PAUSED: "UNPAUSE MEMBER",
  PAUSING: "CANCEL PAUSE REQUEST",
  UNPAUSING: "CANCEL UNPAUSE REQUEST",
};

interface Props extends PauseDetailsProps {
  onButtonClick: (name: string) => void;
  pauseStatus: string;
  routeEditPauseModal: () => void;
  canEditPause: boolean;
}

export const OtherActions: React.FC<Props> = ({
  onButtonClick,
  pauseStatus,
  reason,
  startDate,
  endDate,
  totalPausedDays,
  previousReason,
  previousStartDate,
  previousEndDate,
  routeEditPauseModal,
  canEditPause,
}: Props) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={BODY_STYLE}
    style={CARD_STYLES.card}
    title="Other Actions"
    extra={
      canEditPause ? (
        <Icon
          type="edit"
          theme="filled"
          style={ICON_SIZE}
          onClick={() => routeEditPauseModal()}
        />
      ) : null
    }
  >
    <PauseDetails
      pauseStatus={pauseStatus}
      reason={reason}
      startDate={startDate}
      endDate={endDate}
      totalPausedDays={totalPausedDays}
      previousReason={previousReason}
      previousStartDate={previousStartDate}
      previousEndDate={previousEndDate}
    />
    <Button
      type="primary"
      style={BUTTON_STYLE}
      onClick={() => onButtonClick("pauseMember")}
    >
      {pauseButtonTitle[pauseStatus]}
    </Button>
  </Card>
);

export default OtherActions;
