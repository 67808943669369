import React, { useState } from "react";
import moment from "moment-timezone";
import { getCheckInTimezoneString, TIME_FORMAT } from "utils/date";
import { Modal } from "components/Modal";
import ReviewTaskInfo from "components/ReviewTaskInfo";
import { message as alert, Tabs } from "@wellth/web-ui";
import * as constants from "containers/MemberProfile/constants";
import { MEMBER_PROFILE_REFETCH } from "utils";
import { TaskCalendarEvent } from "containers/MemberProfile/components/TaskCalendar";
import {
  useUpdateCheckInMutation,
  useCreateMealCheckInMutation,
  useCreateMedicationCheckInMutation,
  useCreateMeasurementCheckInMutation,
} from "hooks/graphql";
import { ObjectDetectionStatus, VerificationStatus } from "types/globalTypes";
import CreditTaskCard from "components/CreditTaskCard";

const { TabPane } = Tabs;

interface ReviewTaskContainerProps {
  goBack: () => void;
  id: string;
  calendarData: Partial<TaskCalendarEvent>;
  timezone: string;
}

const ReviewTaskCalendarContainer: React.FC<ReviewTaskContainerProps> = ({
  calendarData: {
    checkInId,
    checkInIsValid,
    checkInStatus,
    checkInCategory,
    reminderTime,
    checkInTimezone,
    reminderId,
    calendarDate,
    ...calendarData
  },
  timezone,
  goBack,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const requestContext = {
    onCompleted: goBack,
    context: { requireOnline: true },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message }) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        void alert.error(`Error updating check in: ${message}`);
      });
    },
    awaitRefetchQueries: true,
    refetchQueries: MEMBER_PROFILE_REFETCH(id),
  };
  const [rejectCheckIn] = useUpdateCheckInMutation({
    ...requestContext,
    variables: {
      id: checkInId,
      checkInPatch: {
        verificationStatus: VerificationStatus.Rejected,
      },
    },
    onError: ({ graphQLErrors }) =>
      graphQLErrors.forEach(({ message }) => {
        void alert.error(`Error rejecting check in: ${message}`);
      }),
  });
  const [creditExistingCheckIn] = useUpdateCheckInMutation({
    ...requestContext,
  });
  const [createMealCheckIn] = useCreateMealCheckInMutation({
    ...requestContext,
  });
  const [createMeasurementCheckIn] = useCreateMeasurementCheckInMutation({
    ...requestContext,
  });
  const [createMedicationCheckIn] = useCreateMedicationCheckInMutation({
    ...requestContext,
  });

  const showVerifyButton =
    (checkInStatus === constants.MISSED ||
      checkInStatus === constants.REJECTED ||
      checkInStatus === constants.OPEN ||
      !checkInIsValid) &&
    checkInStatus !== constants.NOT_YET_OPEN;

  return (
    <Modal
      title={`${checkInCategory} at ${moment(reminderTime, TIME_FORMAT).format(
        TIME_FORMAT,
      )} ${getCheckInTimezoneString(checkInTimezone, timezone)}`}
      loading={false}
      visible
      onCancel={goBack}
      onOk={goBack}
      footer={null}
      width={700}
    >
      <Tabs defaultActiveKey="taskInfo" size="small" tabBarGutter={0}>
        <TabPane tab="Task Info" key="taskInfo">
          <ReviewTaskInfo
            loading={loading}
            calendarData={{
              reminderId,
              calendarDate,
              checkInId,
              checkInIsValid,
              checkInStatus,
              checkInCategory,
              reminderTime,
              checkInTimezone,
              ...calendarData,
            }}
            rejectCheckIn={async () => {
              setLoading(true);
              await rejectCheckIn();
              setLoading(false);
            }}
            timezone={timezone}
          />
        </TabPane>
        <TabPane
          tab="Credit Check In"
          key="creditCheckIn"
          disabled={!showVerifyButton}
        >
          <CreditTaskCard
            type="check-in"
            loading={loading}
            creditTask={async ({ reason, reasonDetail, note }) => {
              setLoading(true);
              if (checkInId) {
                await creditExistingCheckIn({
                  variables: {
                    id: checkInId,
                    checkInPatch: {
                      verificationStatus: VerificationStatus.Verified,
                      isCredited: true,
                      creditedReason: reason,
                      creditedReasonDetail: reasonDetail,
                      creditedReasonNote: note,
                    },
                  },
                });
                return;
              }
              const values = {
                isCredited: true,
                creditedReason: reason,
                creditedReasonDetail: reasonDetail,
                creditedReasonNote: note,
                verificationStatus: VerificationStatus.Verified,
                category: checkInCategory,
                reminderId,
                patientId: id,
                verifierId: null,
                checkInTimezone: checkInTimezone || moment.tz.guess(),
                objectDetectionStatus: ObjectDetectionStatus.Unknown,
                checkInTimestamp: moment
                  .tz(
                    `${moment(calendarDate).format(
                      "YYYY-MM-DD",
                    )} ${reminderTime}`,
                    checkInTimezone || moment.tz.guess(),
                  )
                  .utc()
                  .toDate(),
              };
              switch (checkInCategory) {
                case constants.MEDICATION:
                  await createMedicationCheckIn({
                    variables: {
                      input: {
                        medicationCheckIn: {
                          ...values,
                        },
                      },
                    },
                  });

                  break;
                case constants.MEAL:
                  await createMealCheckIn({
                    variables: {
                      input: {
                        mealCheckIn: { ...values },
                      },
                    },
                  });
                  break;
                case constants.WEIGHT:
                case constants.GLUCOSE:
                case constants.BLOOD_PRESSURE:
                case constants.SLEEP:
                case constants.SLEEP_CONNECTED:
                  await createMeasurementCheckIn({
                    variables: {
                      input: {
                        measurementCheckIn: {
                          ...values,
                        },
                      },
                    },
                  });
                  break;
                default:
                  break;
              }
              setLoading(false);
            }}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ReviewTaskCalendarContainer;
