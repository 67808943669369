import React, { useImperativeHandle } from "react";
import { Form, FormInputType } from "@wellth/web-ui";
import { PauseStatus } from "types/globalTypes";
import isEmpty from "lodash/isEmpty";

export interface PauseStatusFormProps {
  reason: string;
  note: string;
  pauseStatus: PauseStatus;
}

export interface FormProps extends Partial<PauseStatusFormProps> {
  disablePrimary?: boolean;
  inputStyle?: React.CSSProperties;
}

export const PAUSE_REASON_OPTIONS = [
  {
    title: "Missed Check-ins / Can’t reach",
    value: "Missed Check-ins / Can’t reach",
  },
  {
    title: "Admitted to facility/hospital",
    value: "Admitted to facility/hospital",
  },
  {
    title: "Personal Emergency / Family Crisis",
    value: "Personal Emergency / Family Crisis",
  },
  { title: "Phone issues", value: "Phone issues" },
  { title: "App Issue / Bug", value: "App Issue / Bug" },
  {
    title: "Connection / Internet Issue",
    value: "Connection / Internet Issue",
  },
  { title: "Other Technical Issue(s)", value: "Other Technical Issue(s)" },
  {
    title: "Insufficient supplies / needs refill",
    value: "Insufficient supplies / needs refill",
  },
  {
    title: "Prolonged illness/complication",
    value: "Prolonged illness/complication",
  },
  { title: "Rewards - Delay", value: "Rewards - Delay" },
  { title: "Rewards - Other Issues", value: "Rewards - Other Issues" },
  { title: "Travel or moving", value: "Travel or moving" },
  { title: "Paused - per Customer", value: "Paused - per Customer" },
  { title: "Voluntary Departure", value: "Voluntary Departure" },
  {
    title: "Care plan no longer supported",
    value: "Care plan no longer supported",
  },
  { title: "Other", value: "Other" },
];

const pauseInformation = (status: PauseStatus): string[] => {
  switch (status) {
    case PauseStatus.Active:
      return [
        "Are you sure you want to Pause the Member?",
        "",
        "When Paused, Members:",
        "-- do not receive Push Notification reminders",
        "-- do not receive SMS notification reminders",
        "-- are not penalized for missing Check-ins",
        "-- will not receive payouts",
        "-- WILL receive a manual SMS text sent from the Dashboard",
      ];
    case PauseStatus.Pausing:
      return ["Are you sure you want to cancel the pause request?"];
    case PauseStatus.Paused:
      return ["Are you sure you want to unpause member?"];
    case PauseStatus.Unpausing:
      return ["Are you sure you want to cancel the unpause request?"];
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Invalid PauseStatus ${status}`);
  }
};

const ChangePauseStatusForm: React.RefForwardingComponent<any, FormProps> = (
  {
    inputStyle = {
      height: "2.1875rem",
      width: "18.75rem",
    },
    pauseStatus,
    ...formProps
  }: FormProps,
  ref,
) => {
  const formRef = React.useRef(null);
  useImperativeHandle(ref, () => ({
    getPauseStatus: () =>
      new Promise((resolve, reject) => {
        if (!formRef.current) {
          return resolve({});
        }
        return formRef.current.props.form.validateFields((errors, values) => {
          if (errors) {
            return reject(errors);
          }
          return resolve(values);
        });
      }),
  }));

  return (
    <>
      {pauseStatus === PauseStatus.Active ? (
        <Form
          {...formProps}
          wrappedComponentRef={formRef}
          mapPropsToFields={({ note, reason }, createFormField) => ({
            reason: createFormField({ value: reason }),
            note: createFormField({
              value: note,
            }),
          })}
          inputs={[
            {
              type: FormInputType.Dropdown,
              title: "Reason:",
              key: "reason",
              placeholder: "Select a reason",
              style: inputStyle,
              options: PAUSE_REASON_OPTIONS,
              decoratorOptions: {
                rules: [
                  { required: true, message: "Please provide a reason." },
                ],
              },
            },
            {
              type: FormInputType.TextArea,
              title: "Add note (optional):",
              placeholder: "Write a note...",
              key: "note",
              style: { minWidth: inputStyle.width },
              autoSize: { minRows: 4 },
            },
          ]}
        />
      ) : null}
      <div style={{ flexDirection: "column" }}>
        {pauseInformation(pauseStatus).map((message, i) =>
          isEmpty(message) ? <br key={i} /> : <div key={i}>{message}</div>,
        )}
      </div>
    </>
  );
};

export default React.forwardRef(ChangePauseStatusForm);
