import React, { useRef, useImperativeHandle } from "react";
import { Form, FormInputType } from "@wellth/web-ui";
import styles from "./styles";

export interface ManualCreditFormProps {
  creditAmount: number;
  note: string;
}

export interface ManualCreditFormRef {
  getManualCreditInput: () => Promise<ManualCreditFormProps>;
}

export type CreateManualCreditFormProps = Partial<ManualCreditFormProps>;

const CreateManualCreditFormRFC: React.RefForwardingComponent<
  ManualCreditFormRef,
  CreateManualCreditFormProps
> = (formProps, ref) => {
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getManualCreditInput: () =>
      new Promise((resolve, reject) =>
        formRef.current.props.form.validateFields((errors, values) =>
          errors ? reject(errors) : resolve(values),
        ),
      ),
  }));

  return (
    <Form
      {...formProps}
      wrappedComponentRef={formRef}
      inputContainerStyle={styles.inputContainerStyle}
      inputs={[
        {
          type: FormInputType.Text,
          title: "Manual Credit Amount ($)",
          key: "creditAmount",
          placeholder: "Credit Amount",
          itemProps: { style: styles.itemStyle },
          rowProps: { gutter: undefined },
          columnProps: { span: undefined },
          decoratorOptions: {
            rules: [
              {
                required: true,
                message:
                  "Credit amount is required and should be in the 00.00 format, no greater than 30.00.",
                pattern: /^[0-9]{0,2}[.]?[0-9]{0,2}$/,
              },
              {
                validator: (_, value): Promise<void> =>
                  +value <= 30.0
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Credit value must be less than 30.00"),
                      ),
              },
            ],
          },
        },
        {
          type: FormInputType.TextArea,
          title: "Note",
          key: "note",
          placeholder: "Write a note...",
          itemProps: { style: styles.itemStyle },
          rowProps: { gutter: undefined },
          columnProps: { span: undefined },
          autoSize: false,
        },
      ]}
    />
  );
};

export const CreateManualCreditForm = React.forwardRef(
  CreateManualCreditFormRFC,
);

export default CreateManualCreditForm;
