/* eslint-disable no-underscore-dangle */
import React from "react";
import moment from "moment-timezone";
import { find } from "lodash";
import {
  TaskCalendar,
  TaskEvent,
  CheckInStatus,
} from "components/TaskCalendar";
import {
  AppointmentLocation,
  CalendarEventFragment,
  PersonVisitType,
  AppointmentStatusType,
} from "types/globalTypes";
import { DATE_FORMAT, TIME_FORMAT } from "utils/date";
import { APPOINTMENT, ENROLLMENT } from "containers/MemberProfile/constants";

const normalizeAppointments = (
  appointments: AppointmentType[],
  primaryTimezone: string,
): TaskEvent[] =>
  appointments.map(({ __typename, appointmentId, dueTime, status }) => ({
    start: moment
      .tz(dueTime, primaryTimezone)
      .subtract(4, "h")
      .toDate(),
    end: moment
      .tz(dueTime, primaryTimezone)
      .subtract(4, "h")
      .toDate(),
    taskCategory: __typename,
    photoUrl: null,
    taskIsValid: true,
    taskStatus: status,
    taskId: appointmentId,
    taskTime: moment.tz(dueTime, primaryTimezone).format(TIME_FORMAT),
    isDemo: false,
    calendarDate: dueTime,
    taskDate: moment(dueTime).format(DATE_FORMAT),
  }));

const normalizeRenderCalendarView = (
  renderCalendarView: TaskCalendarEvent[],
  enrollmentDate: string,
): TaskEvent[] => {
  const normalizeCalendarView = renderCalendarView.map((task) => {
    const displayDateTime = task.checkInLocalTime
      ? `${moment(task.calendarDate).format("YYYY-MM-DD")} ${
          task.checkInLocalTime
        }`
      : `${moment(task.calendarDate).format("YYYY-MM-DD")} ${
          task.reminderTime
        }`;
    return {
      start: moment(displayDateTime)
        .subtract(4, "h")
        .toDate(),
      end: moment(displayDateTime)
        .subtract(4, "h")
        .toDate(),
      taskCategory: task.checkInCategory,
      photoUrl: task.photoUrl,
      taskIsValid: task.checkInIsValid,
      taskStatus: task.checkInStatus as CheckInStatus,
      taskId: task.reminderId,
      taskTime: task.reminderTime,
      isDemo: task.isDemo,
      calendarDate: moment(task.calendarDate, DATE_FORMAT).toDate(),
      taskDate: task.reminderDate,
    };
  });
  return [
    {
      start: moment(enrollmentDate).toDate(),
      end: moment(enrollmentDate).toDate(),
      taskCategory: ENROLLMENT,
      photoUrl: "",
      taskIsValid: true,
      taskStatus: ENROLLMENT,
      taskId: "enrollment",
      taskTime: "",
      isDemo: true,
      calendarDate: moment(enrollmentDate).toDate(),
      taskDate: "",
    },
    ...normalizeCalendarView,
  ];
};

export interface AppointmentType {
  __typename?: "Appointment";
  appointmentId: string;
  type: PersonVisitType;
  name?: string;
  dueTime?: Date;
  isAllDay?: boolean;
  lossAmount?: number;
  lossCurrencySymbol?: string;
  location?: AppointmentLocation;
  isMissed?: boolean;
  status?: AppointmentStatusType;
  allowCredit?: boolean;
}

interface CalendarContainerProps {
  enrollmentDate: string;
  primaryTimezone: string;
  appointments: AppointmentEvent[];
  renderCalendarView: TaskCalendarEvent[];
  routeCheckIn: (event: TaskCalendarEvent, memberTimezone: string) => void;
  routeAppointment: (event: AppointmentEvent, memberTimezone: string) => void;
}
export type AppointmentEvent = AppointmentType;
export type TaskCalendarEvent = Partial<CalendarEventFragment>;

const TaskCalendarContainer: React.FC<CalendarContainerProps> = ({
  enrollmentDate,
  primaryTimezone,
  appointments,
  renderCalendarView,
  routeCheckIn,
  routeAppointment,
}: CalendarContainerProps) => (
  <TaskCalendar
    events={normalizeAppointments(appointments, primaryTimezone).concat(
      normalizeRenderCalendarView(renderCalendarView, enrollmentDate),
    )}
    onHandleSelectEvent={({ taskDate, taskId, taskCategory }) => {
      if (taskCategory === ENROLLMENT) {
        return;
      }
      if (taskCategory === APPOINTMENT) {
        routeAppointment(
          find(
            appointments,
            (appointment) =>
              moment(taskDate).format(DATE_FORMAT) ===
                moment(appointment.dueTime).format(DATE_FORMAT) &&
              taskId === appointment.appointmentId,
          ),
          primaryTimezone,
        );
      } else {
        routeCheckIn(
          find(
            renderCalendarView,
            (task) =>
              moment(taskDate).format("YYYY-MM-DD") === task.reminderDate &&
              taskId === task.reminderId,
          ),
          primaryTimezone,
        );
      }
    }}
  />
);

export default TaskCalendarContainer;
