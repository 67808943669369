enum ReminderType {
  medication = "Medication",
  bloodPressure = "Blood Pressure",
  glucose = "Glucose",
  meal = "Meal",
  other = "Other",
}

enum ProgramTemplate {
  ma_payer = "MA - Payer",
  ma_dsnp = "MA - DSNP",
  medicaid = "Medicaid",
  commercial = "Commercial",
  ma_provider = "MA - Provider",
  marketplace = "Marketplace",
  other = "Other",
}

const requiredReminderNote =
  "‼PRESCRIBED MEDICATIONS ONLY (including injectables, maintenance inhalers)— NO OTC VITAMINS, OTC meds, etc.‼";
const otherReminderNote = (reminders: string[]) => {
  if (reminders.length === 0) return ""; // Handle empty array
  let reminderString = reminders[0];
  if (reminders.length === 1) {
    return `Other reminders can include: ${reminderString}. Recommend the reminder most related to their conditions.`;
  }
  reminderString =
    reminders.slice(0, -1).join(", ") +
    " or " +
    reminders[reminders.length - 1];

  return `Other reminders can include: ${reminderString}. Recommend the reminder most related to their conditions.`;
};
const additionalRemindersEncouraged =
  "These additional reminders are especially encouraged.";
const bloodPressureNotFeasibleNote =
  "If Blood Pressure reminder is not feasible, you can enroll with Medication, Glucose or Meal";
const medicationNotFeasibleNote =
  "If Medication reminder not feasible, you can enroll with Blood Pressure or Glucose.";
const medicationNotFeasiblePickTwoNote =
  "If Medication is not feasible, can enroll with two of the following - Blood Pressure, Glucose, or Meal";
const needsManagerApproval =
  "NEEDS MANAGER APPROVAL: If Medication reminder is not feasible, you can enroll with Blood Pressure, Glucose, or Meal";
const includeBloodPressure = "Include blood pressure if feasible.";
const bloodGlucoseEspeciallyEncouraged =
  "Glucose and Blood Pressure are especially encouraged. Please prioritize a Glucose task if possible.";
const prioritizeGlucose = "Please prioritize a Glucose task if possible.";

export type ReminderConfigType = {
  type: ReminderType;
  notes: string[];
  required: boolean;
};

const defaultReminderConfig = ({
  otherRequired,
  noteReminders,
  additionalOtherNotes = [],
}: {
  otherRequired: boolean;
  noteReminders: string[];
  additionalOtherNotes?: string[];
}): ReminderConfigType[] => [
  {
    type: ReminderType.medication,
    notes: [requiredReminderNote],
    required: true,
  },
  {
    type: ReminderType.other,
    notes: [otherReminderNote(noteReminders), ...additionalOtherNotes],
    required: otherRequired,
  },
];

export const reminderConfigByProgramTemplate = (
  programTemplate: string,
): ReminderConfigType[] => {
  switch (programTemplate) {
    case ProgramTemplate.ma_payer:
    case ProgramTemplate.ma_dsnp:
      return defaultReminderConfig({
        otherRequired: true,
        noteReminders: [
          ReminderType.glucose,
          ReminderType.bloodPressure,
          ReminderType.meal,
        ],
        additionalOtherNotes: [bloodGlucoseEspeciallyEncouraged],
      });
    case ProgramTemplate.medicaid:
    case ProgramTemplate.commercial:
    case ProgramTemplate.ma_provider:
    case ProgramTemplate.marketplace:
    case ProgramTemplate.other:
      return defaultReminderConfig({
        otherRequired: false,
        noteReminders: [ReminderType.glucose, ReminderType.bloodPressure],
        additionalOtherNotes: [prioritizeGlucose],
      });
    default:
      return defaultReminderConfig({
        otherRequired: true,
        noteReminders: [
          ReminderType.glucose,
          ReminderType.bloodPressure,
          ReminderType.meal,
        ],
      });
  }
};

export const programSpecificReminderConfigs: {
  [key: string]: ReminderConfigType[];
} = {
  "GCHP-QI-M-2023": [
    {
      type: ReminderType.bloodPressure,
      notes: [bloodPressureNotFeasibleNote, requiredReminderNote],
      required: true,
    },
    {
      type: ReminderType.other,
      notes: [
        otherReminderNote([ReminderType.medication, ReminderType.glucose]),
      ],
      required: false,
    },
  ],
  "Molina-ACA-2023": [
    {
      type: ReminderType.medication,
      notes: [medicationNotFeasibleNote, requiredReminderNote],
      required: true,
    },
    {
      type: ReminderType.other,
      notes: [
        otherReminderNote([ReminderType.bloodPressure, ReminderType.glucose]),
        additionalRemindersEncouraged,
      ],
      required: false,
    },
  ],
  "DOHC-2022": [
    {
      type: ReminderType.medication,
      notes: [requiredReminderNote],
      required: true,
    },
    {
      type: ReminderType.other,
      notes: [
        otherReminderNote([ReminderType.bloodPressure, ReminderType.glucose]),
        additionalRemindersEncouraged,
      ],
      required: false,
    },
  ],
  "PH-DSNP-2023": [
    {
      type: ReminderType.medication,
      notes: [needsManagerApproval, requiredReminderNote],
      required: true,
    },
    {
      type: ReminderType.other,
      notes: [
        otherReminderNote([
          ReminderType.bloodPressure,
          ReminderType.glucose,
          ReminderType.meal,
        ]),
      ],
      required: true,
    },
  ],
  "HSH-MA-1": [
    {
      type: ReminderType.medication,
      notes: [requiredReminderNote],
      required: true,
    },
    {
      type: ReminderType.other,
      notes: [
        otherReminderNote([
          ReminderType.bloodPressure,
          ReminderType.glucose,
          ReminderType.meal,
        ]),
        includeBloodPressure,
      ],
      required: true,
    },
  ],
  "OKCH-MA-2023": [
    {
      type: ReminderType.medication,
      notes: [medicationNotFeasiblePickTwoNote, requiredReminderNote],
      required: true,
    },
    {
      type: ReminderType.other,
      notes: [
        otherReminderNote([
          ReminderType.bloodPressure,
          ReminderType.glucose,
          ReminderType.meal,
        ]),
      ],
      required: true,
    },
  ],
  "BSWHP-MA-2024": [
    {
      type: ReminderType.medication,
      notes: [medicationNotFeasiblePickTwoNote, requiredReminderNote],
      required: true,
    },
    {
      type: ReminderType.other,
      notes: [
        otherReminderNote([
          ReminderType.bloodPressure,
          ReminderType.glucose,
          ReminderType.meal,
        ]),
      ],
      required: true,
    },
  ],
};

export const getReminderConfigs = ({
  programCode,
  programTemplate,
}: {
  programCode: string;
  programTemplate: string;
}): ReminderConfigType[] => {
  const reminderConfigs = programSpecificReminderConfigs[programCode];
  return reminderConfigs
    ? reminderConfigs
    : reminderConfigByProgramTemplate(programTemplate);
};
